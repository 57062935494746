import { merge } from 'lodash';
import { PropsWithChildren } from 'react';

import { LoadingOverlay } from '~/components/loading-overlay';
import { useAuthenticationContext } from '~/contexts/Authentication/hooks';
import { Context } from '~/contexts/User/context';
import { CurrentUser } from '~/data/types/current-user';
import { useCurrentUserQuery } from '~/data/use-user';

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    state: { accessToken, isAuthenticated }
  } = useAuthenticationContext();
  const {
    data: userExtended = {} as CurrentUser,
    isError,
    isLoading
  } = useCurrentUserQuery(isAuthenticated);

  if (isError) {
    // TODO: Error modal?
    return null;
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const { name, sub: emailAddress } = accessToken?.claims ?? {};

  const value = merge(
    {
      accounts: [],
      emailAddress: isAuthenticated ? emailAddress : null,
      name: isAuthenticated ? name : null
    },
    userExtended
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
