const routeConstraint =
  '([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}|por-[0-7][0-9A-HJKMNP-TV-Z]{25})';

export interface AccountIdParam {
  accountId: string;
}

export interface AccountHolderIdParam {
  accountHolderId: string;
}

export interface SubaccountIdParam {
  subaccountId: string;
}

const ACCOUNT_ID_PARAM = `:accountId${routeConstraint}`;
const SUBACCOUNT_ID_PARAM = `:subaccountId${routeConstraint}`;

//
// GLOBAL
//

export const PATH_ACCOUNTS = '/';
export const PATH_CONTACT_US = '/contact-us';
export const PATH_NOT_FOUND = '*';
export const PATH_PRIVACY_NOTICE = '/privacy-notice';
export const PATH_TERMS_AND_CONDITIONS = '/terms-and-conditions';

//
// ACCOUNT
//

const PATH_ACCOUNT = `/account/${ACCOUNT_ID_PARAM}/${SUBACCOUNT_ID_PARAM}`;
export const PATH_ACCOUNT_OVERVIEW = `${PATH_ACCOUNT}/overview`;
export const PATH_ACCOUNT_PINS = `${PATH_ACCOUNT}/pins`;
export const PATH_ACCOUNT_ADDRESSES = `${PATH_ACCOUNT}/addresses`;
export const PATH_ACCOUNT_ADDRESSES_DEPOSIT = `${PATH_ACCOUNT_ADDRESSES}/deposit`;
export const PATH_ACCOUNT_ADDRESSES_WITHDRAWAL = `${PATH_ACCOUNT_ADDRESSES}/withdrawal`;
export const PATH_ACCOUNT_DOCUMENTS = `${PATH_ACCOUNT}/documents`;
export const PATH_ACCOUNT_DOCUMENTS_INVOICES = `${PATH_ACCOUNT_DOCUMENTS}/invoices`;
export const PATH_ACCOUNT_DOCUMENTS_STATEMENTS = `${PATH_ACCOUNT_DOCUMENTS}/statements`;
export const PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS = `${PATH_ACCOUNT_DOCUMENTS}/trade-confirmations`;
export const PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS = `${PATH_ACCOUNT_DOCUMENTS}/transaction-confirmations`;
export const PATH_ACCOUNT_POSITIONS = `${PATH_ACCOUNT}/positions`;
export const PATH_ACCOUNT_TRANSACTIONS = `${PATH_ACCOUNT}/transactions`;

//
// AUTHENTICATION
//

export const PATH_AUTHENTICATION__SIGN_IN = '/signin'; // Must be `signin` to match OktaSignIn router

//
// USER - PROFILE
//

export const PATH_USER__PROFILE = '/user/profile';
