import { createContext, PropsWithChildren, useContext } from 'react';

import { LoadingOverlay } from '~/components/loading-overlay';
import { ActiveAccount, ActiveSubAccount } from '~/contexts/Account/types';
import { Account } from '~/data/types/account';
import { useAccountQuery } from '~/data/use-account';

const Context = createContext<ActiveAccount | undefined>(undefined);

Context.displayName = 'AccountContext';

export interface AccountContextProviderProps {
  accountId: string;
  subaccountId: string;
}

export const AccountContextProvider = ({
  accountId,
  children,
  subaccountId
}: PropsWithChildren<AccountContextProviderProps>) => {
  const {
    data: account,
    isError,
    isLoading
  } = useAccountQuery({
    id: accountId
  });

  if (isError) {
    // TODO: Error modal?
    return null;
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const { subaccounts, users, ...restAccount } = account as Account;
  const subaccount = subaccounts?.find(({ id }) => id === subaccountId) as ActiveSubAccount;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    ...restAccount,
    subaccount,
    subaccounts,
    users
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Returns data about the current account.
 *
 * @returns AccountContext
 * @throws Error
 */
export function useAccountContext(): ActiveAccount {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAccountContext must be used within a AccountContextProvider');
  }

  return context;
}

export * from './types';
